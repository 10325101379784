import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { m } from 'framer-motion';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { CardActionArea, Link } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import Image from '../../../../components/image';
import { ListItem } from './styles';
// ----------------------------------------------------------------------
export const NavItem = forwardRef(({ item, open, isOffset, active, subItem, isExternalLink, ...other }, ref) => {
    const { title, path, children } = item;
    const renderContent = (_jsxs(ListItem, { ref: ref, disableRipple: true, isOffset: isOffset, subItem: subItem, active: active, open: open, ...other, children: [title, !!children && _jsx(Iconify, { width: 16, icon: "eva:arrow-ios-downward-fill", sx: { ml: 1 } })] }));
    // ExternalLink
    if (isExternalLink) {
        return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", children: renderContent }));
    }
    // Has child
    if (children) {
        return renderContent;
    }
    // Default
    return (_jsx(Link, { component: RouterLink, to: path, underline: "none", children: renderContent }));
});
export function NavItemDashboard({ item, sx, ...other }) {
    return (_jsx(Link, { component: RouterLink, to: item.path, sx: { width: 1 }, ...other, children: _jsx(CardActionArea, { sx: {
                py: 5,
                px: 10,
                minHeight: 400,
                borderRadius: 1,
                color: 'text.disabled',
                bgcolor: 'background.neutral',
                ...sx,
            }, children: _jsx(m.div, { whileTap: "tap", whileHover: "hover", variants: {
                    hover: { scale: 1.02 },
                    tap: { scale: 0.98 },
                }, children: _jsx(Image, { visibleByDefault: true, alt: "illustration_dashboard", src: "/assets/illustrations/illustration_dashboard.png" }) }) }) }));
}
